<template>
  <lenon-modal
    title="Class Promotion Flow"
    :show="promotionFlowModalOpened"
    size="lg"
    @onClose="closeGradeModal()"
  >
    <error-display :error="error" />
    <div>
      <div
        v-if="promotionFlow.length"
        class="mb-1"
      >
        <small>
          <b>
            This shows the order in which students will be promoted from one class to another, you can update it accordingly.
          </b>
        </small>
      </div>
      <div
        v-for="(flow,index) in promotionFlow"
        :key="index"
        class="row mb-2 align-items-center"
        style="border-bottom: 1px gray dashed"
      >
        <div class="col-md-3">
          <lenon-select
            v-model="flow.prev_class"
            y
            value-name="id"
            label-name="name"
            label="Prev Class"
            :options="classes"
          />
        </div>
        <div class="col-md-3">
          <lenon-select
            v-model="flow.current_class"
            value-name="id"
            label-name="name"
            label="Current Class"
            :options="classes"
          />
        </div>
        <div class="col-md-3">
          <lenon-select
            v-model="flow.next_class"
            value-name="id"
            label-name="name"
            label="Next Class"
            :options="classes"
          />
        </div>
        <div class="col-md-3 mt-md-1">
          <lenon-button
            label="Remove"
            variant="flat-danger"
            icon="XIcon"
            @onClick="removeFlow(index)"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <lenon-button
            variant="outline-primary"
            class="float-right"
            label="Add Flow"
            icon="PlusIcon"
            @onClick="addFlow()"
          />
        </div>
      </div>
    </div>
    <template slot="modal-actions">
      <b-row class="float-right">
        <lenon-button
          variant="flat-danger"
          icon="XIcon"
          class="mr-1"
          label="Cancel"
          @onClick="closeGradeModal()"
        />
        <lenon-button
          label="Save"
          :loading="promotionFlowLoading"
          loading-text="Loading..."
          @onClick="save()"
        />
      </b-row>
    </template>
  </lenon-modal>
</template>

<script>

import {
  BRow,
} from 'bootstrap-vue'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
// import draggable from 'vuedraggable'
import { UPDATE_PROMOTION_FLOW_M } from '@/graphql/mutations'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'

export default {
  name: 'PromotionFlow',
  components: {
    ErrorDisplay,
    LenonSelect,
    LenonButton,
    LenonModal,
    BRow,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      promotionFlowModalOpened: false,
      promotionFlowLoading: false,
      promotionFlow: [],
      error: {},
    }
  },
  computed: {
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    flow() {
      return this.$store.getters['studentPromotion/promotionFlow']
    },
  },
  watch: {
    modalOpened(opened) {
      this.promotionFlowModalOpened = opened
    },
    flow(val) {
      this.promotionFlow = val
    },
  },
  methods: {
    addFlow() {
      this.promotionFlow.push({
        id: null,
        current_class: null,
        next_class: null,
        prev_class: null,
      })
    },
    removeFlow(index) {
      this.promotionFlow.splice(index, 1)
    },
    getClasses(index, type) {
      const idsToIgnore = []
      this.promotionFlow.forEach((flow, idx) => {
        if (type === 'prev') {
          if (idx !== index) {
            idsToIgnore.push(flow.prev_class)
          }
        }
        if (type === 'current') {
          if (idx !== index) {
            idsToIgnore.push(flow.current_class)
          }
        }
        if (type === 'next') {
          if (idx !== index) {
            idsToIgnore.push(flow.next_class)
          }
        }
      })
      return this.classes.filter(cls => !idsToIgnore.includes(cls.id))
    },
    save() {
      this.error = {}
      this.promotionFlowLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_PROMOTION_FLOW_M,
        variables: {
          input: this.promotionFlow,
        },
      }).then(res => {
        this.showSuccess('Updated Successfully')
      }).catch(err => {
        this.error = err
        this.showError('Failed to update promotion flow')
      }).finally(() => {
        this.promotionFlowLoading = false
      })
    },
    closeGradeModal() {
      this.promotionFlowModalOpened = false
      this.$emit('modalClosed')
    },
  },
}
</script>
