<template>
  <div class="academic-years">
    <lenon-list-page
      title="Student Promotion"
      :columns="columns"
      :rows="modifiedPromotionStandards"
      :table-loading="tableLoading"
      :show-create="false"
      :hide-table="true"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start desktop-only">
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            variant="outline-warning"
            icon="RepeatIcon"
            class="mr-2"
            label="Promotion Flow"
            tool-tip-text="Customize class promotion flow"
            @onClick="showPromotionFlowModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            icon="UserCheckIcon"
            label="Bulk Promotion"
            tool-tip-text="Update promotion statuses of students in selected classes"
            class="mr-2"
            variant="outline-warning"
            :loading="autoPromoteLoading"
            @onClick="showPromoteStudentsModal()"
          />
          <!--          <lenon-button-->
          <!--            v-responsive="$store.getters['app/largeScreen']"-->
          <!--            icon="SettingsIcon"-->
          <!--            label="Standards"-->
          <!--            tool-tip-text="Manage Promotion Standards (CRUD)"-->
          <!--            class=""-->
          <!--            @onClick="showPromotionStandardModal()"-->
          <!--          />-->
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="RepeatIcon"
              text="Promotion Flow"
              @click="showPromotionFlowModal()"
            />
            <lenon-dropdown-item
              icon="UserCheckIcon"
              text="Bulk Promotion"
              @click="showPromoteStudentsModal()"
            />
            <!--            <lenon-dropdown-item-->
            <!--              icon="SettingsIcon"-->
            <!--              text="Standards"-->
            <!--              @click="showPromotionStandardModal()"-->
            <!--            />-->
          </lenon-dropdown>
        </div>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #consider_improvement="{row}">
        {{ row.item.consider_improvement ? 'Yes' : 'No' }}
      </template>

    </lenon-list-page>
    <promotion-flow
      :modal-opened="promotionFlowModalOpened"
      @modalClosed="closeModal('promotion')"
    />
    <promotion-standard
      :modal-opened="promotionStandardModalOpened"
      @modalClosed="closeModal('promotionStandard')"
    />
    <lenon-modal
      :show="promoteStudentsModalOpened"
      title="Update Promotion Status"
      ok-text="Update"
      size="md"
      :loading="autoPromoteLoading"
      :ok-disabled="selectedClasses.length===0"
      @onClose="()=>{promoteStudentsModalOpened= false}"
      @onOk="runAutoPromoteRoutine()"
    >
      <b-form-checkbox
        v-model="promotionStatus"
        class="mb-1"
      >
        Promotion Status ({{ promotionStatus ? 'Promoted' : 'Repeated' }})
      </b-form-checkbox>
      <lenon-multi-select
        v-model="selectedClasses"
        :options="classes"
        label-name="name"
        rules="required"
        label="Select Classes"
      />
      <div class="mt-1">
        <strong>
          The promotion statuses of students in the selected classes will be updated for the current term.
        </strong>
      </div>
      <div class="mt-5" />
    </lenon-modal>
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import PromotionFlow from '@/views/admin/academics/student-promotion/PromotionFlow.vue'
import PromotionStandard from '@/views/admin/academics/student-promotion/PromotionStandard.vue'
import { PROMOTION_STANDARDS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import { RUN_AUTO_PROMOTE_ROUTINE_M } from '@/graphql/mutations'
import confirm from '@/lenon/mixins/confirm'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonModal from '@/lenon/components/LenonModal'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'StudentPromotion',
  components: {
    LenonMultiSelect,
    LenonModal,
    LenonDropdown,
    LenonDropdownItem,
    LenonListPage,
    PromotionStandard,
    PromotionFlow,
    LenonButton,
    BFormCheckbox,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      promotionFlowModalOpened: false,
      promotionStandardModalOpened: false,
      tableLoading: false,
      autoPromoteLoading: false,
      promoteStudentsModalOpened: false,
      promotionStatus: false,
      selectedClasses: [],
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'absence_rate',
          label: 'Absence Rate (%)',
        },
        {
          key: 'min_cum_average',
          label: 'Min Cum Average (%)',
        },
        {
          key: 'max_cum_average',
          label: 'Max Cum Average (%)',
        },
        {
          key: 'consider_improvement',
          label: 'Consider Improvement',
        },
        {
          key: 'improvement_rate',
          label: 'Improvement Rate (%)',
        },
      ],
    }
  },
  computed: {
    classes() {
      return this.$store.getters['termsClasses/termClasses']
    },
    modifiedPromotionStandards() {
      return this.$store.getters['studentPromotion/modifiedPromotionStandards']
    },
  },
  mounted() {
    this.fetchPromotionStandard()
  },
  methods: {
    showPromoteStudentsModal() {
      this.promoteStudentsModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'promotionStandard':
          this.promotionStandardModalOpened = false
          break
        case 'promotion':
          this.promotionFlowModalOpened = false
      }
    },
    runAutoPromoteRoutine() {
      this.autoPromoteLoading = true
      this.$apollo.mutate({
        mutation: RUN_AUTO_PROMOTE_ROUTINE_M,
        variables: {
          status: this.promotionStatus,
          classes: this.selectedClasses.map(c => c.id),
        },
      })
        .then(res => {
          this.autoPromoteLoading = false
          if (res.data && res.data.runAutoPromoteRoutine) {
            this.showSuccess('Promotion statuses updated successfully.')
          } else {
            this.showInfo('Promotion statuses can not be updated at this time, the current term is not a promotional term.')
          }
        })
        .catch(err => {
          this.autoPromoteLoading = false
        })
    },
    showPromotionStandardModal() {
      this.promotionStandardModalOpened = true
    },
    showPromotionFlowModal() {
      this.promotionFlowModalOpened = true
    },
    fetchPromotionStandard() {
      this.$apollo.query({ query: PROMOTION_STANDARDS_Q })
        .then(res => {
          this.$store.commit('studentPromotion/setPromotionStandards', res.data.promotionStandards)
          this.$store.commit('studentPromotion/setPromotionFlow', res.data.promotionFlow)
        })
    },
  },
}
</script>
